import $ from 'jquery';

const initRmaItemSelector = () => {
  $('#order-return-form table thead input[type=checkbox]').on('click', function() {
    var checked = $(this).prop('checked');
    $('#order-return-form table tbody input[type=checkbox]').each(function(_, checkbox) {
      $(checkbox).prop('checked', checked);
    });
  });
};

const filterLineTableCustomer = () => {
  if (typeof filtersTable.lines !== 'undefined') {
    filtersTable.lines.forEach((line) => {
      const code = line.getAttribute('data-code');
      const dateExp = line.getAttribute('data-dateExp');
      let validate = 0;

      if (code.includes(filtersTable.code) || filtersTable.code === '') {
        validate += 1;
      }

      if (dateExp !== '' && filtersTable.dateExp !== '') {
        let date = new Date(dateExp);
        date.setHours(1);
        if (date.getTime() >= filtersTable.dateExp.getTime()) {
          validate += 1;
        }
      } else {
        validate += 1;
      }

      if (validate == 2) {
        line.classList.remove('d-none');
      } else {
        line.classList.add('d-none');
      }
    });
  }
}

const sortTableCustomer = () => {
  let filtersTable = {};
  const lines = document.querySelectorAll('.js-table-line-sort');
  const code = document.querySelector('.js-table-sort-code');
  const dateExp = document.querySelector('.js-table-sort-date');
  if (lines.length > 0) {
    filtersTable.lines = lines;
  }
  if (code !== null) {
    filtersTable.code = '';
    code.addEventListener('input', (e) => {
      const value = e.currentTarget.value;
      filtersTable.code = value.trim().toLowerCase();
      filterLineTableCustomer();
    })
  }
  if (dateExp !== null) {
    filtersTable.dateExp = '';
    dateExp.addEventListener('input', (e) => {
      const value = e.currentTarget.value;
      let date = '';
      if (value !== '') {
        date = new Date(value);
        date.setHours(1);
      }
      filtersTable.dateExp = date;
      filterLineTableCustomer();
    })
  }

  window.filtersTable = filtersTable;
}

function setupCustomerScripts() {
  if ($('body#order-detail')) {
    initRmaItemSelector();
  }
  if ($('body#discount')) {
    sortTableCustomer();
  }
}

$(document).ready(setupCustomerScripts);
