import $ from 'jquery';

$(document).ready(function () {
  var rtl = false;
  if (prestashop.language.is_rtl == '1') {
    rtl = true;
  }
  productBottomSlider(rtl);
});

function productBottomSlider(rtl) {
  var obj = '.js-crossselling-slider, .js-viewedproduct-slider, .js-accessories-slider, .js-category-products-slider, .js-featuredproducts-slider';
  $(obj).slick({
    slidesToShow: 8,
    slidesToScroll: 4,
    adaptiveHeight: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    dots: false,
    arrows: true,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ],
  });
  $(obj).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(obj).find('.slick-active img.js-lazy, .slick-active img.js-simple-lazy').trigger("appear");
  });
}