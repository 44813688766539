import $ from 'jquery';
import prestashop from 'prestashop';
import 'velocity-animate';

/* Ajax Add to cart */
function ajaxAddToCart() {
  if (prestashop.configuration.is_catalog === false) {
    var psAjaxCart = false,
        waitting_html = '<div class="js-waitting-addtocart page-loading-overlay add-to-cart-loading"><div class="page-loading-backdrop"><span class="uil-spin-css"><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span></span></div></div>';
    if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
      psAjaxCart = varPSAjaxCart;
    }

    $('body').on('click', '.js-ajax-add-to-cart', function (event) {
      event.preventDefault();

      var $btn = $(this);
      if (!$btn.find('.js-waitting-addtocart').length) {
        $(this).append(waitting_html);
      }
      $btn.removeClass('added').addClass('disabled');

      let actionURL = prestashop.urls.pages.cart,
          query = 'id_product=' + $btn.data('id-product') + '&add=1&action=update&token=' + prestashop.static_token,
          qty_val = 1,
          qty = $btn.closest('.js-product-miniature').find('.js-add-to-cart-quantity');
      if (qty.length && parseInt(qty.val()) > 1) {
        qty_val = parseInt(qty.val());
      }
      query = query + '&qty=' + qty_val;

      $.post(actionURL, query, null, 'json').then(function (resp) {
        prestashop.emit('updateCart', {
          reason: {
            idProduct: resp.id_product,
            idProductAttribute: resp.id_product_attribute,
            linkAction: 'add-to-cart'
          }
        });

        if (resp.success) {
          $btn.addClass('added');
          if (!psAjaxCart) {
            window.location.href = prestashop.urls.pages.cart + '?action=show';
          }
        }
        if (resp.hasError) {
          $('.js-modal-message-text').text(resp.errors[0]);
          $('.js-modal-message').modal('show');
        }
      }).fail(function (resp) {
        prestashop.emit('handleError', { eventType: 'addProductToCart', resp: resp });
      });

      return false;
    });
  }
}

function createListingSpin()
{
  $.each($('input.js-add-to-cart-quantity'), function (index, spinner) {
    $(spinner).makeTouchSpin();
  });
}

/* Quickview */
function quickviewFunction() {
  var waitting_html = '<div class="js-waitting-quickview page-loading-overlay quickview-loading"><div class="page-loading-backdrop"><span class="uil-spin-css"><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span><span><span></span></span></span></div></div>';

  $('body').on('click', '[data-link-action="quickview"]', function (event) {
    if (!$(this).find('.js-waitting-quickview').length) {
      $(this).append(waitting_html);
    }
    $(this).addClass('disabled');
  });

  prestashop.on('clickQuickView', function (elm) {
    let data = {
      'action': 'quickview',
      'id_product': elm.dataset.idProduct,
      'id_product_attribute': elm.dataset.idProductAttribute,
    };
    $.post(prestashop.urls.pages.product, data, null, 'json').then(function (resp) {
      $('body').append(resp.quickview_html);
      let productModal = $('#quickview-modal-'+resp.product.id+'-'+resp.product.id_product_attribute);
      productModal.modal('show');
      productConfig(productModal);
      productModal.on('hidden.bs.modal', function () {
        productModal.remove();
      });

      $('[data-link-action="quickview"]').removeClass('disabled');
    }).fail(function(resp) {
      prestashop.emit('handleError', {eventType: 'clickQuickView', resp: resp});
    });
  });

  var productConfig = function(qv) {
    let $mask = qv.find('.js-product-thumbs-scrollbox'),
        $thumbnails = qv.find('.js-thumb'),
        $cover = qv.find('.js-qv-product-cover'),
        $quantity = qv.find('#quantity_wanted'),
        $src = qv.find('.js-product-attributes-source'),
        $dest = qv.find('.js-product-attributes-destination'),
        $src2 = qv.find('.js-product-availability-source'),
        $dest2 = qv.find('.js-product-availability-destination');

    if (qv.find('.js-product-combinations').length) {
      qv.find('.js-product-variants').hide();
    }

    qv.find('.js-countdown-wrapper').runCountdown({
      new_specific_prices_to: qv.find('[data-specific-prices-to]').data('specific-prices-to')
    });

    $dest.empty();
    if ($src.length) {
      $dest.html($src.html()); //$src.remove();
    }

    $dest2.empty();
    if ($src2.length) {
      $dest2.html($src2.html()); //$src2.remove();
    }

    $thumbnails.on('click', function (event) {
      $thumbnails.removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $cover.attr('src', $(event.currentTarget).data('image'));
    });

    setTimeout(function(){ 
      $mask.makeFlexScrollBox();
    }, 500);

    $quantity.makeTouchSpin();
  };
}

/* Search filters - Facets */
function searchFiterFacets() {
  var dataGrid = $('#js-product-list').data('grid-columns'),
      storage = window.localStorage || window.sessionStorage;

  const parseSearchUrl = function (event) {
    if (event.target.dataset.searchUrl !== undefined) {
      return event.target.dataset.searchUrl;
    }

    if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL');
    }

    return $(event.target).parent()[0].dataset.searchUrl;
  };

  $('body').on('change', '#search_filters input[data-search-url]', function (event) {
    togglePendingIcon();
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-filters-clear-all', function (event) {
    togglePendingIcon();
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-link', function (event) {
    if( !$(this).parents('.page-list').length ){
      event.preventDefault();
      togglePendingIcon();
      prestashop.emit('updateFacets', $(event.target).closest('a').get(0).href);
    }    
  });

  $('body').on('change', '#search_filters select', function (event) {
    const form = $(event.target).closest('form');
    togglePendingIcon();
    prestashop.emit('updateFacets', '?' + form.serialize());
  });

  prestashop.on('updateProductList', function(data) {
    updateProductListDOM(data);

    $('#js-product-list').find('.js-product-list-view').removeClass('columns-2 columns-3 columns-4 columns-5').addClass(dataGrid);

    if (storage && storage.productListView) {
      $('#product_display_control a[data-view="' + storage.productListView + '"]').trigger('click');
    }
    createListingSpin();

    setTimeout(function() {
      togglePendingIcon();
    }, 500);

    // if ($('#js-product-list-top').length) {
    //   $.smoothScroll({
    //     scrollTarget: '#js-product-list-top',
    //     offset: -50,
    //   });
    // }
  });
}
function togglePendingIcon() {
  $('.js-pending-query').fadeToggle();
}

function updateProductListDOM (data) {
  $('#search_filters').replaceWith(data.rendered_facets);
  $('#js-active-search-filters').replaceWith(data.rendered_active_filters);
  $('#js-product-list-top').replaceWith(data.rendered_products_top);
  $('#js-product-list').replaceWith(data.rendered_products);
  $('#js-product-list-bottom').replaceWith(data.rendered_products_bottom);
  //if (data.rendered_products_header) {
  //  $('#js-product-list-header').replaceWith(data.rendered_products_header);
  //}
}

/* Grid - List - Table */
function productDisplayControl() {
  var displayControl = '#product_display_control a',
      storage =  window.localStorage || window.sessionStorage;

  $('body').on('click', displayControl, function (event) {
    event.preventDefault();
    
    let view = $(this).data('view');
    $(displayControl).removeClass('selected');
    $(this).addClass('selected');
    $('.js-product-list-view').removeClass('grid list table-view').addClass(view);
    
    try {
      storage.setItem('productListView', view);
    }
    catch (error) {
      console.log('Can not cache the product list view');
    }
  });

  if (storage.productListView) {
    $(displayControl + '[data-view="' + storage.productListView + '"]').trigger('click');
  } else {
    $(displayControl + '.selected').trigger('click');
  }
}

/* Mobile search filter toggle */
function mobileSearchFilterToggle() {
  $('body').on('click', '#search_filter_toggler', function () {
    $('#_mobile_search_filters').stop().slideToggle();
  });
  $('#search_filter_controls .ok').on('click', function () {
    $('#_mobile_search_filters').stop().slideUp();
  });
}

function sldMoveSortOrder() {
  const sortOrder = document.querySelector('.sld-js-sort-orders');
  const AS4Header = document.querySelector('.pm_advancedsearch4_header');
  if (sortOrder !== null && AS4Header !== null) {
    AS4Header.append(sortOrder);
  }
}

function sldMoveFilterMobile() {
  const categoryMobile = document.querySelectorAll('#category.a-mobile-device, #module-pm_advancedsearch4-searchresults.a-mobile-device');
  const PMSearch4 = document.querySelector('.PM_ASBlockOutput');
  const facetMobile = document.querySelector('.sld-js-search-container');
  const btnFacetMobileToggle = document.querySelector('.sld-js-filter-mobile');
  if (categoryMobile.length  > 0 && PMSearch4 !== null && facetMobile !== null && btnFacetMobileToggle !== null) {
    facetMobile.append(PMSearch4);
    btnFacetMobileToggle.addEventListener('click', (e) => {
      e.preventDefault();
      $('.PM_ASBlockOutput').slideToggle();
    });
  }
}

$(document).on('as4-After-Set-Results-Contents', function(e, idSearch, context) {
  sldMoveSortOrder();
});

$(document).ready(function() {
  quickviewFunction();
  searchFiterFacets();
  productDisplayControl();
  mobileSearchFilterToggle();
  ajaxAddToCart();
  createListingSpin();
  sldMoveSortOrder();
  sldMoveFilterMobile();
});


